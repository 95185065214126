<template>
  <div
    class="contain-select-width"
  >
    <v-select
      :items="optionItems"
      dense
      outlined
      hide-details
      :label="label"
      v-bind="$props"
      v-on="$listeners"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "EngagementRateSelector",

  props: {
    label: {
      type: String,
      required: false,
      default: "Percentage"
    },

    value: {
      type: Number,
      required: false,
      default: 0.01
    },

    includeDecimalValues: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    optionItems() {
      const items = []

      // In case we have to include decimal values, we need to add 0.1 and 0.5 options
      if (this.includeDecimalValues) {
        items.push({
          text: "≥ 0.1%",
          value: 0.001
        },
        {
          text: "≥ 0.5%",
          value: 0.005
        })
      }

      for (let i = 1; i <= 20; i++) {
        items.push({
          text: `≥ ${i}%`,
          value: i / 100
        })
      }

      return items
    }
  },

  mounted() {
    this.$emit('input', this.value)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  min-width 12em
  width 12em
</style>
